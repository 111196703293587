import { useEffect, useState, useContext, useMemo } from "react";
import styles from "./AgeGateLocation.module.scss";
import Link from "next/link";
import StoreContext from "../../context/store-context";
import { LottieIcon } from "../../components/Lottie/icon";
import GooglePlacesMinimal from "../../components/location/GooglePlacesMinimal";
import Button from "../../components/Button/button";
import deliveryTruck from "../../public/icons/animated/car.json";
import shoppingBags from "../../public/icons/animated/shopping-bags.json";
import { useShoppingCart } from "../../context/shopping-cart-context";
import SkeletonElement from "../../components/skeletons/SkeletonElement";
import { convertTo12HourFormat } from "../../utils/formatTime";

export default function AgeGate({ router, ageGate, setAgeGate }) {
  const {
    setOrderType,
    setStore,
    setStoreName,
    setZipcode,
    orderType,
    address,
  } = useContext(StoreContext);
  const { setCartItems } = useShoppingCart();
  const [tab, setTab] = useState(2);
  const [pickupStores, setPickupStores] = useState([]);
  const [deliveryStores, setDeliveryStores] = useState([]);
  const [deliveryZips, setDeliveryZips] = useState([]);
  const [selectedPickupStore, setSelectedPickupStore] = useState(null);
  const [selectedDeliveryStore, setSelectedDeliveryStore] = useState(null);
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState(null);
  const [warning, setWarning] = useState(false);

  useMemo(() => {
    if (orderType === "Delivery") setTab(1);
    else if (orderType === "Pickup") setTab(2);
  }, [orderType]);

  // prevent scrolling body when age gate is open
  useEffect(() => {
    const ageGateValue = localStorage.getItem("ageGate");
    const body = document.querySelector("body");

    const preventScroll = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    if (router.asPath === "/terms-of-use" || router.asPath === "/privacy-policy") {
      setAgeGate(false);
      body.style.overflowY = "scroll";
      body.removeEventListener("touchmove", preventScroll);
    } else if (ageGateValue) {
      setAgeGate(false);
      body.style.overflowY = "scroll";
      body.removeEventListener("touchmove", preventScroll);
    } else {
      setAgeGate(true);
      body.style.overflowY = "hidden";
      body.addEventListener("touchmove", preventScroll, { passive: false }); // Prevent touch scrolling
    }

    return () => {
      body.style.overflowY = "scroll";
      body.removeEventListener("touchmove", preventScroll);
    };
  }, [ageGate, router, router.asPath]);


  useEffect(() => {
    const fetchStores = async () => {
      try {
        const response = await fetch("/api/jane-stores");
        if (!response.ok) throw new Error("Network response was not ok");

        const data = await response.json();
        const stores = data?.data?.stores || [];
        const storesWithPickup = stores.filter(
          (store) => store.pickup && store.id !== 117 && store.id !== 2100
        );
        const storesWithDelivery = stores.filter((store) => store.delivery);

        setPickupStores(storesWithPickup);
        setDeliveryStores(storesWithDelivery);

        if (storesWithDelivery.length) {
          const zipPromises = storesWithDelivery.map(async (store) => {
            const res = await fetch(`/api/jane-store/${store.id}`);
            if (!res.ok) throw new Error("Failed to fetch delivery zips");
            const storeData = await res.json();
            return storeData?.data?.store?.delivery_zipcodes;
          });

          const zips = await Promise.all(zipPromises);
          setDeliveryZips(zips.flat());
        }
      } catch (error) {
        console.error("Error fetching stores:", error);
      }
    };

    fetchStores();
  }, []);

  const handleTabChange = (id) => {
    setTab(id);
    setWarning(false);

    // Reset selected stores when switching tabs
    if (id === 1) {
      setSelectedPickupStore(null); // Clear pickup selection when switching to delivery
    } else if (id === 2) {
      setSelectedDeliveryStore(null); // Clear delivery selection when switching to pickup
      setSelectedDeliveryAddress(null); // Clear delivery address as well
    }
  };

  const handleConfirm = () => {
    if (tab === 1 && selectedDeliveryAddress && selectedDeliveryStore) {
      setOrderType("Delivery");
      setStore(selectedDeliveryStore.id);
      setStoreName(selectedDeliveryAddress);
      setZipcode(selectedDeliveryStore.zip);
    } else if (tab === 2 && selectedPickupStore) {
      setOrderType("Pickup");
      setStore(selectedPickupStore.id);
      setStoreName(selectedPickupStore.full_address.match(/^[^,]+/)[0]); // first part of the address
      setZipcode(selectedPickupStore.zip);
      console.log(selectedPickupStore)
      setCartItems([]); // Clear cart when switching stores
    } else {
      setWarning(true);
      return;
    }
    localStorage.setItem("ageGate", true);
    setAgeGate(false);
  };

  return (
    ageGate && (
      <div className={styles.ageGate}>
        <div className={styles.content}>
        <div
            className={`${styles.orderTypeSelection} ${warning ? styles.orderTypeSelectionWarning : ""
              }`}
          >
            <div className={styles.tabs}>
              <Tab id={2} name="Pickup" icon={shoppingBags} tab={tab} setTab={handleTabChange} />
              <Tab id={1} name="Delivery" icon={deliveryTruck} tab={tab} setTab={handleTabChange} />
            </div>

            <div className={styles.tabContent}>
              {tab === 1 && (
                <div className={styles.deliveryTab}>
                  {deliveryZips.length > 0 ? (
                    <GooglePlacesMinimal
                      selectedAddress={selectedDeliveryAddress}
                      setSelectedDeliveryAddress={(address) => {
                        setSelectedDeliveryAddress(address);
                        setWarning(false);
                      }}
                      setSelectedDeliveryStore={(store) => {
                        setSelectedDeliveryStore(store);
                        setWarning(false);
                      }}
                      deliveryStores={deliveryStores}
                      deliveryZips={deliveryZips}
                    />
                  ) : (
                    null
                  )}
                </div>
              )}
              {tab === 2 && (
                <div className={styles.pickupTab}>
                    <div className={styles.pickupLocations}>
                  {pickupStores.length > 0 ? (
                    pickupStores.map((store) => (
                      <PickupLocation
                        key={store.id}
                        store={store}
                        selectedStore={selectedPickupStore}
                        setSelectedStore={setSelectedPickupStore}
                        setWarning={setWarning}
                      />
                    ))
                  ) : (
                    <>
                      <PickupLocationSkeleton />
                      <PickupLocationSkeleton />
                    </>
                  )}
                </div>
                </div>
              )}
            </div>
          </div>
          {warning && <div className={styles.warningText}>Please select a location</div>}

          <div className="fw-bold fs-200 text-center pt-2">
            You must be 21+ to enter this site
          </div>

          <div className={styles.buttons}>
            <Button
              type="button"
              className={`button openLocationButton primary ${(tab === 1 && !selectedDeliveryStore) || (tab === 2 && !selectedPickupStore)
                ? "disabled"
                : ""
                }`}
              data-type="primary"
              style={{ borderRadius: "50px" }}
              onClick={handleConfirm}
              disabled={
                (tab === 1 && (!selectedDeliveryAddress || !selectedDeliveryStore)) ||
                (tab === 2 && !selectedPickupStore)
              }
            >
              I'm old enough
            </Button>
            <Button
              type="link"
              href="about:blank"
              className={`link underline ${styles.tooYoungButton}`}
              style={{ fontSize: "0.875rem" }}
            >
              I'm too young
            </Button>
          </div>

          <div>
            <div className={`fs-200 color-neutral-650 text-center ${styles.terms}`}>
              By entering this site I agree to the{" "}
              <Link href="/terms-of-use" className="link" style={{ textDecoration: "underline" }}>
                Terms of Service
              </Link>{" "}
              &amp;{" "}
              <Link
                href="/privacy-policy"
                className="link"
                style={{ textDecoration: "underline" }}
              >
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

function Tab({ id, name, tab, setTab, icon }) {
  const [animate, setAnimate] = useState(false);

  const handleClick = () => {
    setTab(id);
    setAnimate(true);
    setTimeout(() => setAnimate(false), 500);
  };

  return (
    <button
      className={
        tab === id
          ? `${styles.tab} ${styles.active} lottie-dark-hover`
          : `${styles.tab} ${animate ? "lottie-dark-hover" : "lottie-dark"}`
      }
      onClick={handleClick}
    >
      <LottieIcon animatedIcon={icon} animate={animate} />
      <span>{name}</span>
    </button>
  );
}

function PickupLocation({ store, selectedStore, setSelectedStore, setWarning }) {
  const handleStoreSelect = () => {
    setSelectedStore(store);
    setWarning(false);
    console.log(store)
  };

  const storeName = store?.name.includes('(')
    ? store.name.match(/\(([^)]+)\)/)[1] // Extract content inside parentheses
    : store?.name.replace("Purple Lotus - ", ""); // Remove "Purple Lotus - " if no parentheses

  return (
    <div
      className={`${styles.store} ${selectedStore?.id === store.id ? styles.selected : styles.unselected
        }`}
      onClick={handleStoreSelect}
    >
      <div className={styles.storeLeft}>
        {/* Store Name */}
        <div className={styles.storeTitle}>{storeName}</div>

        {/* Pickup Hours */}
        <div className={styles.hours}>
          {store.hours.pickup_hours.map((i, index) => {
            const today = new Date();
            const currentDay = new Intl.DateTimeFormat("en-US", {
              weekday: "long",
            }).format(today);

            const isToday = i.day.toLowerCase() === currentDay.toLowerCase();
            if (isToday) {
              return (
                <div key={index} className={styles.hourBlock}>
                  <div>
                    {convertTo12HourFormat(i.period.from)} - {convertTo12HourFormat(i.period.to)}
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>

      {/* Store Address */}
      <div className={styles.storeRight}>
        <div className={styles.location}>
          <div className="fs-300">
            {store.full_address?.replace(/\bCalifornia\b/, "CA")}
          </div>
        </div>
      </div>
    </div>
  );
}


function PickupLocationSkeleton() {
  return (
    <div className={styles.store}>
      <div className={styles.skeleton}>
        <div className={styles.skeletonLeft}>
          <SkeletonElement type="title" />
          <SkeletonElement type="text" />
        </div>
        <div className={styles.skeletonRight}>
          <SkeletonElement type="text" />
          <SkeletonElement type="text" />
        </div>
      </div>
    </div>
  );
}